import {defineStore} from "pinia";
import {useAppsStore} from "@/stores/app";
import {useCookies} from "vue3-cookies";

const {cookies} = useCookies();

export const useTicketsStore = defineStore({
    id: "tickets",
    state: () => ({
        tickets: [],
        totalTickets: {
            currentPage: 0,
            totalItems: 0,
            totalPages: 0,
        },
        ticketsDetails: null,
    }),
    getters: {
        getTickets: (state) => state.tickets,
        getTotalTickets: (state) => state.totalTickets,
        getTicketsDetails: (state) => state.ticketsDetails,
    },
    actions: {
        async loadTickets(data, shimmer = false) {
            const appStore = useAppsStore();
            if (shimmer) {
                appStore.addShimmerSection('packages');
            } else {
                appStore.loadingStart();
            }
            return await this.$http
                .get(
                    `/web/services/tickets`,
                    {
                        params: data,
                    }
                )
                .then((response) => {
                    this.tickets = response.data.data.data;
                    this.totalTickets.currentPage = response.data.data.current_page;
                    this.totalTickets.totalItems = response.data.data.total;
                    this.totalTickets.totalPages = response.data.data.last_page;
                    return true;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    if (shimmer) {
                        appStore.removeShimmerSection('packages');
                    } else {
                        appStore.loadingFinish();
                    }
                })
        },


        async loadTicketsDetails(id, shimmer = false) {
            this.packageDetails = null;
            const appStore = useAppsStore();
            if (shimmer) {
                appStore.addShimmerSection('package-details');
            } else {
                appStore.loadingStart();
            }
            return await this.$http
                .get(
                    `/web/services/tickets/detail/${id}`
                )
                .then((response) => {
                    this.ticketsDetails = response.data.data;
                    return true;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    if (shimmer) {
                        appStore.removeShimmerSection('package-details');
                    } else {
                        appStore.loadingFinish();
                    }
                });
        }
    }
});
