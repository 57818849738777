import {defineStore} from "pinia";
import {useAppsStore} from "@/stores/app";
import {useCookies} from "vue3-cookies";

const {cookies} = useCookies();

export const useHomeStore = defineStore({
    id: "home",
    state: () => ({
        home: [],
    }),
    getters: {
        getHome: (state) => state.home,
        getAds: (state) => {
            //get the home['section'] == 'ads'
            return state.home.find((section) => section.section === 'ads');
        },
        getCountries: (state) =>  state.home.find((section) => section.section == 'countries'),
    },
    actions: {
        async loadHome() {
            const appStore = useAppsStore();
            appStore.addShimmerSection('home');
            return await this.$http
                .get(
                    `/web/customization`
                )
                .then((response) => {

                    this.home = response.data.data;
                    return true;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    appStore.removeShimmerSection('home');
                });
        },
    },
});
