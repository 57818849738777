import {defineStore} from "pinia";
import {useAppsStore} from "@/stores/app";
import {useCookies} from "vue3-cookies";

const {cookies} = useCookies();

export const usePackageStore = defineStore({
    id: "packages",
    state: () => ({
        packages: [],
        totalPackages: {
            currentPage: 0,
            totalItems: 0,
            totalPages: 0,
        },
        packageDetails: null,
        hotelDetails: null,
    }),
    getters: {
        getPackages: (state) => state.packages,
        getTotalPackages: (state) => state.totalPackages,
        getPackageDetails: (state) => state.packageDetails,
        getHotelDetails: (state) => state.hotelDetails,
    },
    actions: {
        async loadPackages(data, shimmer = false) {
            const appStore = useAppsStore();
            if (shimmer) {
                appStore.addShimmerSection('packages');
            } else {
                appStore.loadingStart();
            }
            return await this.$http
                .get(
                    `/web/services/packages`,
                    {
                        params: data,
                    }
                )
                .then((response) => {
                    this.packages = response.data.data.data;
                    this.totalPackages.currentPage = response.data.data.current_page;
                    this.totalPackages.totalItems = response.data.data.total;
                    this.totalPackages.totalPages = response.data.data.last_page;
                    return true;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    if (shimmer) {
                        appStore.removeShimmerSection('packages');
                    } else {
                        appStore.loadingFinish();
                    }
                })
        },


        async loadPackageDetails(id, shimmer = false) {
            this.packageDetails = null;
            const appStore = useAppsStore();
            if (shimmer) {
                appStore.addShimmerSection('package-details');
            } else {
                appStore.loadingStart();
            }
            return await this.$http
                .get(
                    `/web/services/packages/detail/${id}`
                )
                .then((response) => {
                    this.packageDetails = response.data.data;
                    return true;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    if (shimmer) {
                        appStore.removeShimmerSection('package-details');
                    } else {
                        appStore.loadingFinish();
                    }
                });
        },


        async loadPackageHotelDetails(id,hotelId, shimmer = false) {
            this.packageDetails = null;
            const appStore = useAppsStore();
            if (shimmer) {
                appStore.addShimmerSection('hotel-details');
            } else {
                appStore.loadingStart();
            }
            return await this.$http
                .get(
                    `/web/services/packages/detail/${id}/hotel/${hotelId}`
                )
                .then((response) => {
                    this.hotelDetails = response.data.data;
                    return true;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    if (shimmer) {
                        appStore.removeShimmerSection('hotel-details');
                    } else {
                        appStore.loadingFinish();
                    }
                });
        }

    },
});
