import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
// import { Tooltip } from "bootstrap";
import App from "./App.vue";


import router from "./router";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import VueMoment from "vue-moment-v3";
import { VsxIcon } from "vue-iconsax";

//imports for app initialization
import axiosInstance from "@/core/plugins/axios";
import toastification from "@/core/plugins/toastification";
import globalMixin from "@/core/plugins/globalMixin";
import "@/core/plugins/firebase";
import VueGoogleMaps from '@fawmi/vue-google-maps'

import VueLazyLoad from 'vue3-lazyload';
import lazyImage from "/media/svg/default_img.svg";
import defaultImage from "/media/svg/default_img.svg";
import { QuillEditor } from '@vueup/vue-quill';
import VueChatScroll from 'vue3-chat-scroll';
import { preventE } from './directives/prevent-e-directive';
import defaultImageDirective from "./directives/defaultImage";
import ElementPlusLocaleAR from 'element-plus/dist/locale/ar.js';
import ElementPlusLocaleARCustom from '@/core/plugins/element-plus-dist-locale-ar.ts';

import '@vueup/vue-quill/dist/vue-quill.snow.css';
import "vue-toastification/dist/index.css";

import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import vue3LoadingShimmer from "vue3-loading-shimmer";


import vue3GoogleLogin from 'vue3-google-login'
const gauthOption = {
  clientId: '178800449420-hfqmgh9eid72655hchh630neqa5t9aqr.apps.googleusercontent.com',
  scope: 'email profile openid',
};

import VueAppleLogin from 'vue-apple-login';

import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css"; // required if you're not going to override default slots
import * as Sentry from "@sentry/vue";

const pinia = createPinia();

const app = createApp(App);
const mergedLocale = {
  ...ElementPlusLocaleAR,
  ...ElementPlusLocaleARCustom,
};



app.use(pinia);
pinia.use(({ store }) => {
  store.$http = markRaw(app.config.globalProperties.$http);
  store.$notificationMessage = markRaw(
    app.config.globalProperties.$notificationMessage
  );
  store.$t = markRaw(app.config.globalProperties.$t);
});

const time = new Date();
app.use(VueAppleLogin, {
  clientId: 'com.safarfy-signin.app',
  scope: 'name email',
  redirectURI: `${window.location.origin}/${i18n.global.locale.value}/login`,
  state: time.toString(),
  usePopup: true,
});

toastification(app);
axiosInstance(app);

if (process.env.NODE_ENV === 'production') {
  
Sentry.init({
  app,
  dsn: 'https://a2b7e97a9196fc12ebfbe677c0361d20@o4504638363795456.ingest.us.sentry.io/4508284832514048',
  integrations: [

  ],
  debug: false,
});
}
app.mixin(globalMixin);
app.provide("http", app.config.globalProperties.$http);
app.provide("t", app.config.globalProperties.$t);

app.directive('prevent-e', preventE);
app.directive("default-image", defaultImageDirective);

app.use(vue3GoogleLogin, gauthOption);
app
  .use(router)
  .use(ElementPlus, {
    locale: mergedLocale,
  })
  .use(i18n)
  .use(VueMoment)
  .use(VueChatScroll)
  .use(VueViewer.default, {
    debug: true
  })
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyBqj2P0Cs3kD0WE9Ow27vsPCSfjvCK2p3g',
      language: 'ar',
      libraries: "places"
    },
  })
  .use(VueLazyLoad, {
    loading: lazyImage,
    error: defaultImage,
  });

app.component("infinite-loading", InfiniteLoading).component("VsxIcon", VsxIcon).component('QuillEditor', QuillEditor);
app.component("shimmer", vue3LoadingShimmer);

app.mount("#app");
