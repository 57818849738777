import {defineStore} from "pinia";
import {useAppsStore} from "@/stores/app";
import {useCookies} from "vue3-cookies";

const {cookies} = useCookies();

export const useAdsStore = defineStore({
    id: "ads",
    state: () => ({
        ads: [],
    }),
    getters: {
        getAds: (state) => state.ads
    },
    actions: {
        async loadAds() {
            const appStore = useAppsStore();
            appStore.addShimmerSection('ads');
            return await this.$http
                .get(
                    `/web/ads`
                )
                .then((response) => {

                    this.ads = response.data.data;
                    return true;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    appStore.removeShimmerSection('ads');
                });
        },
    },
});
