// src/directives/defaultImage.ts
import { DirectiveBinding } from "vue";

export default {
    beforeMount(el: HTMLImageElement, binding: DirectiveBinding) {
        el.onerror = () => {
            console.log('Image failed to load, applying default image');
            el.src = binding.value || "/media/svg/default_img.svg";
        };
    },
};