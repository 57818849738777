import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();
export const useAccountStore = defineStore({
  id: "account",
  state: () => ({
    accountData: null,
    unreadNotifications: 0,
  }),

  getters: {
    getAccountData: (state) => state.accountData,
    getUnreadNotifications: (state) => state.unreadNotifications
  },

  actions: {
    async loadAccountData() {
      return await this.$http.get(`/user/profile`)
        .then((response) => {
          this.accountData = response.data.data;
          this.unreadNotifications = response.data.data.unread_notifications;
          return true;
        })
        .catch((error) => {
          throw error;
        });
    },
    async updateUnreadNotifications(length) {
      this.unreadNotifications = this.unreadNotifications - length;
    },

    async updateAccountData(data) {
      // const appStore = useAppsStore();
      // appStore.loadingStart();
      return await this.$http.post(`/user/profile/update`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },

      })
        .then((response) => {
          this.accountData = response.data.data;
          // appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.saved_successfully'),
            type: "success",
          });
          return response.data.data;
        })
        .catch((error) => {
          // appStore.loadingFinish();
          throw error;
        });
    },

    async changePassword(data) {
      return await this.$http.post(`/user/profile/change-password`, data)
        .then((response) => {
          this.$notificationMessage({
            title: this.$t('success_messages.change_password_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          throw error;
        });
    },



    async logout() {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http.post(`/user/auth/fcm/unregister`, {
        platform: "web",
        token: localStorage.getItem('fcm_token')??"temp",
      })
        .then(async (res) => {
          localStorage.removeItem('fcm_token');
          await this.$http.get(`/user/profile/logout`)
            .then((response) => {
              appStore.loadingFinish();
              cookies.remove("safarfy-user-login-data");
              cookies.remove("safarfy-user-login-data", "/");
              cookies.remove("safarfy-user-login-data", "/ar");
              cookies.remove("safarfy-user-login-data", "/en");
              return true;
            })
            .catch((error) => {
              appStore.loadingFinish();
              throw error;
            });
        })
        .catch((err) => {
          appStore.loadingFinish();
          throw err;
        });



    },

    async deleteAccount() {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http.post(`/user/profile/delete-account`)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.account_deleted_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


  },
});
