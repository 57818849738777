import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useAccountStore } from "@/stores";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();
export const useNotificationstore = defineStore({
  id: "notifications",
  state: () => ({
    notifications: [],
    totalNotifications: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  }),
  getters: {
    getNotifications: (state) => state.notifications,
    getTotalNotifications: (state) => state.totalNotifications,
  },
  actions: {
    async loadNotifications(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .get(
          `/user/notifications`,
          {
            params: data,
          }
        )
        .then((response) => {
          appStore.loadingFinish();
          this.notifications = response.data.data.data;
          this.totalNotifications.currentPage = response.data.data.current_page;
          this.totalNotifications.totalItems = response.data.data.total;
          this.totalNotifications.totalPages = response.data.data.last_page;
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },

    // markAsRead 
    async markAsRead(data) {
      const accountStore = useAccountStore();

      return await this.$http
        .post(
          `/user/notifications/read`, data
        )
        .then((response) => {
          accountStore.updateUnreadNotifications(data.ids?.length || 0);
          return true;
        })
        .catch((error) => {
          throw error;
        });
    },

    async toggleNotification(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http
        .post(`/user/notifications/toggle`, data)
        .then((response) => {
          appStore.loadingFinish();
          this.$notificationMessage({
            title: this.$t('success_messages.notifications_toggled_successfully'),
            type: "success",
          });
          return true;
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },
  },
});
