<template>
  <RouterView />
</template>

<script lang="ts">
import { defineComponent, nextTick, onBeforeMount, onMounted } from "vue";
import { RouterView } from "vue-router";
import { isUserLoggedIn } from "@/core/helpers/auth";
import { useBodyStore } from "@/stores/body";
import { socialTrakingEvents } from "@/core/helpers/metaPixel.js";
import {
  useAccountStore,
  useNotificationstore,
  useUtilityStore,
  useOrderStore,
} from "@/stores";
import { useChatStore } from "@/stores";

export default defineComponent({
  name: "app",
  components: {
    RouterView,
  },
  setup() {
    const bodyStore = useBodyStore();
    const accountStore = useAccountStore();
    const chatStore = useChatStore();
    const utilityStore = useUtilityStore();
    const notificationStore = useNotificationstore();
    const orderStore = useOrderStore();

    onMounted(() => {
   
      socialTrakingEvents('page_view', {
        fb: {
          
        },
        st: {
        },
        ttq: {
          
        },
      });
      utilityStore.loadContactData();
      nextTick(() => {
        bodyStore.removeBodyClassName("page-loading");
        if (isUserLoggedIn()) {
          accountStore.loadAccountData().then(() => {
            chatStore.loadUnreadMessagesCount(accountStore.getAccountData?.id);
          });
        }
      });
      window.addEventListener("newNotification", (event) => {
        if (event.detail) {
          if (
            event.detail?.storage.data?.order_id &&
            orderStore.getOrderDetails &&
            orderStore.getOrderDetails?.id ==
              event.detail?.storage.data?.order_id
          ) {
            try {
              orderStore.loadOrderDetails(event.detail?.storage.data?.order_id);
            } catch (error) {}
          }

          accountStore.loadAccountData().then(() => {
            chatStore.loadUnreadMessagesCount(accountStore.getAccountData?.id);
          });
          
          orderStore.loadOrders({
            page: 1,
            per_page: 6,
            type: "current",
            status: "",
            service: "",
          });

          notificationStore.loadNotifications({
            page: 1,
            per_page: 12,
            read: "",
          });
        }
      });
    });

    return {
      isUserLoggedIn,
    };
  },
});
</script>

<style lang="scss">
@import "quill/dist/quill.snow.css";
@import "element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/element-ui.dark";
@import "assets/sass/style";

#app {
  display: contents;
}
</style>