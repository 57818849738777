import { defineStore } from "pinia";
import { ElLoading } from 'element-plus'

export const useAppsStore = defineStore({
  id: "apps",
  state: () => ({
    errors: {},
    loading: false,
    shimmerLoading: true,
    btnLoading: false,
    shimmerLoadingSections:[] as string[],
  }),
  getters: {
    getErrors: (state) => state.errors,
    getloading: (state) => state.loading,
    getShimmerLoading: (state) => state.shimmerLoading,
    getBtnLoading: (state) => state.btnLoading,
    getShimmerSections: (state) => state.shimmerLoadingSections,
  },
  actions: {
    async setErrors(errors) {
      this.errors = errors;
    },
    async loadingStart() {
      this.loading = true;
      const loading = ElLoading.service({
        fullscreen: true,
        text: '',
        background: 'rgba(8, 93, 79, 0.2)',
      })
    },
    async loadingFinish() {
      this.loading = false;
      const loading = ElLoading.service({
        fullscreen: true,
        text: '',
        background: 'rgba(8, 93, 79, 0.2)',
      });
      loading.close()
    },

    addShimmerSection(section: string){
        this.shimmerLoadingSections.push(section)
    },
    removeShimmerSection(section:string){
        this.shimmerLoadingSections = this.shimmerLoadingSections.filter(item => item !== section)
    },


    async shimmerLoadingStart() {
      this.shimmerLoading = true;
    },
    async shimmerLoadingFinish() {
      this.shimmerLoading = false;
    },
    async btnLoadingStart() {
      this.btnLoading = true;
    },
    async btnLoadingFinish() {
      this.btnLoading = false;
    },
  },
});
