import { useCookies } from "vue3-cookies";
import { useAccountStore } from '@/stores/modules/account';

const { cookies } = useCookies();

export default async function middleware({ to, next }) {
  let loginRoutes = [
    'login',
    'password-reset',
    'code-confirmation',
    'new-password',
    'register',
    'register-verification',
    'register-data',
  ];

  const loginData = cookies.get("safarfy-user-login-data", {
    parseJSON: true,
  });


  if (loginData) {
    const accountStore = useAccountStore();
    if (loginRoutes.includes(to.name)) {
      return next({ name: "home", params: { lang: to.params.lang } });
    }
    if (to.meta.permissions && to.meta.permissions?.length > 0 && !loginRoutes.includes(to.name)) {
      if (!accountStore.getAccountPermissions || accountStore.getAccountPermissions?.length < 1) {
        await accountStore.loadAccountData();
      }
      if (accountStore.getAccountPermissions?.length > 0 &&
        !accountStore.getAccountPermissions.some((value) =>
          to.meta.permissions.includes(value)
        )
      ) {
        return next({ name: "home", params: { lang: to.params.lang } });
      }
    }
    return next();
  } else {
    if (!loginRoutes.includes(to.name))
      return next({ name: "login", params: { lang: to.params.lang } });
    return next();
  }


}
