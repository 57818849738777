import {defineStore} from "pinia";
import {useAppsStore} from "@/stores/app";
import {useCookies} from "vue3-cookies";

const {cookies} = useCookies();

export const useCountryStore = defineStore({
    id: "countries",
    state: () => ({
        countries: [],
        totalCountries: {
            currentPage: 0,
            totalItems: 0,
            totalPages: 0,
        },
        countryDetails: null,
    }),
    getters: {
        getCountries: (state) => state.countries,
        getTotalCountries: (state) => state.totalCountries,
        getCountryDetails: (state) => state.countryDetails,
    },
    actions: {
        async loadCountries(data, shimmer = false) {
            const appStore = useAppsStore();
            if (shimmer) {
                appStore.addShimmerSection('countries');
            } else {
                appStore.loadingStart();
            }
            return await this.$http
                .get(
                    `/web/countries`,
                    {
                        params: data,
                    }
                )
                .then((response) => {
                    this.countries = response.data.data.data;
                    this.totalCountries.currentPage = response.data.data.current_page;
                    this.totalCountries.totalItems = response.data.data.total;
                    this.totalCountries.totalPages = response.data.data.last_page;
                    return true;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    if (shimmer) {
                        appStore.removeShimmerSection('countries');
                    } else {
                        appStore.loadingFinish();
                    }
                });
        },

        async loadCountryDetails(id, shimmer = false) {
            const appStore = useAppsStore();
            if (shimmer) {
                appStore.addShimmerSection('country-details');
            } else {
                appStore.loadingStart();
            }
            return await this.$http
                .get(
                    `/web/countries/detail/${id}`
                )
                .then((response) => {
                    this.countryDetails = response.data.data;
                    return true;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    if (shimmer) {
                        appStore.removeShimmerSection('country-details');
                    } else {
                        appStore.loadingFinish();
                    }
                })
        },
    },
});
