import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";
import { useRoute } from "vue-router";
import router from "../../router";
import { useAccountStore } from '@/stores/modules/account';

const { cookies } = useCookies();

export const useOrderStore = defineStore({
    id: "orders",
    state: () => ({
        filterData: [],
        orders: [],
        totalOrders: {
            currentPage: 0,
            totalItems: 0,
            totalPages: 0,
        },
        orderDetails: null,
        hotelDetails: null,
    }),
    getters: {
        getFilterData: (state) => state.filterData,
        getOrders: (state) => state.orders,
        getTotalOrders: (state) => state.totalOrders,
        getOrderDetails: (state) => state.orderDetails,
        getHotelDetails: (state) => state.hotelDetails,
    },
    actions: {
        async loadOrdersFilterData() {
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .get(
                    `/web/orders/filter/data`
                )
                .then((response) => {
                    appStore.loadingFinish();
                    this.filterData = response.data.data;
                    return true;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                });
        },

        async loadOrders(data, loadingStart = false, shimmer = false) {
            this.orders = [];
            this.totalOrders = {
                currentPage: 0,
                totalItems: 0,
                totalPages: 0,
            }
            const appStore = useAppsStore();
            if (shimmer) {
                appStore.addShimmerSection('bookings');
            }
            return await this.$http
                .get(
                    `/web/orders`,
                    {
                        params: data,
                    }
                )
                .then((response) => {
                    // if (loadingStart) {
                    //   appStore.loadingFinish();
                    // }
                    this.orders = response.data.data.data;
                    this.totalOrders.currentPage = response.data.data.current_page;
                    this.totalOrders.totalItems = response.data.data.total;
                    this.totalOrders.totalPages = response.data.data.last_page;
                    return true;
                })
                .catch((error) => {
                    // if (loadingStart) {
                    //   appStore.loadingFinish();
                    // }
                    throw error;
                }).finally(() => {
                    if (shimmer) {
                        appStore.removeShimmerSection('bookings');
                    }
                });
        },


        async loadOrderDetails(id, shimmer = false) {
            this.orderDetails = null;
            const appStore = useAppsStore();
            if (shimmer) {
                appStore.addShimmerSection('package-order-details');
            } else {
                appStore.loadingStart();
            }
            return await this.$http
                .get(
                    `/web/orders/detail/${id}`
                )
                .then((response) => {
                    this.orderDetails = response.data.data;
                    return true;
                })
                .catch((error) => {
                    if (error.response?.status == 404) {
                        router.push({ name: '404' })
                    }
                    throw error;
                }).finally(() => {
                    if (shimmer) {
                        appStore.removeShimmerSection('package-order-details');
                    } else {
                        appStore.loadingFinish();
                    }
                })
        },

        async loadOrderHotelDetails(id, hotelId, shimmer = false) {
            this.packageDetails = null;
            const appStore = useAppsStore();
            if (shimmer) {
                appStore.addShimmerSection('hotel-details');
            } else {
                appStore.loadingStart();
            }
            return await this.$http
                .get(
                    `/web/orders/detail/${id}/hotel/${hotelId}`
                )
                .then((response) => {
                    this.hotelDetails = response.data.data;
                    return true;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    if (shimmer) {
                        appStore.removeShimmerSection('hotel-details');
                    } else {
                        appStore.loadingFinish();
                    }
                });
        },

        async addNewPackageOrder(data) {
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .post(`/web/orders/packages/add`, data)
                .then((response) => {
                    appStore.loadingFinish();
                    return true;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                });
        },

        async cancelOrder(data) {
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .post(`/web/orders/cancele`, data)
                .then((response) => {
                    appStore.loadingFinish();
                    this.$notificationMessage({
                        title: this.$t('success_messages.cancelled_successfully'),
                        type: "success",
                    });
                    this.orderDetails = response.data.data;
                    return true;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                });
        },

        async ratingOrder(data) {
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .post(`/web/orders/review`, data)
                .then((response) => {
                    appStore.loadingFinish();
                    this.$notificationMessage({
                        title: this.$t('success_messages.reviewed_successfully'),
                        type: "success",
                    });
                    this.orderDetails = response.data.data;
                    return true;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                });
        },

        async ratingTicketOrder(data) {
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .post(`/web/orders/review/ticket`, data)
                .then((response) => {
                    appStore.loadingFinish();
                    this.$notificationMessage({
                        title: this.$t('success_messages.reviewed_successfully'),
                        type: "success",
                    });
                    this.orderDetails = response.data.data;
                    return true;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                });
        },
        async confirmTransfers(data) {
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .post(`/web/orders/payment/confirm/transfers`, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((response) => {
                    appStore.loadingFinish();
                    this.orderDetails = response.data.data;
                    return true;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                });
        },
        async confirmStripeTransfers(data) {
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .post(`/web/orders/payment/confirm`, data).then((response) => {
                    appStore.loadingFinish();
                    this.orderDetails = response.data.data;
                    return true;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                });
        },
        async confirmOrderStripeTransfers(data) {
            const accountStore = useAccountStore();
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .post(`/web/orders/tickets/add`, data).then(async (response) => {
                    appStore.loadingFinish();
                    this.orderDetails = response.data.data;
                    await accountStore.loadAccountData();
                    return response.data.data;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                });
        },
        async downloadTicketQr(data) {
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .get(`/web/orders/tickets/download/${data.id}`, {
                    responseType: 'blob' // Specify response type as blob

                }).then((response) => {
                    
                    const url = URL.createObjectURL(response.data);

                    // Create a temporary <a> element
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `${data.name}.pdf`;

                    // Append the <a> to the document and trigger click to download
                    document.body.appendChild(a);
                    a.click();

                    // Clean up by revoking the URL and removing the <a> element
                    URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                    appStore.loadingFinish();
                    return response.data.data;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                });
        },
        async checkCoupon(data) {
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .post(`/web/coupons/check`, data).then((response) => {
                    appStore.loadingFinish();
                    return response.data.data;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                });
        },

    },
});
