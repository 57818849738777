import { permissions } from "@/enums/permissions.enum";
import middleware from "../middleware/middleware";
import authMiddleware from "../middleware/auth";

export default [
    {
        path: "",
        component: () => import("@/layouts/AuthLayout.vue"),
        meta: {
            middleware: [authMiddleware],
        },
        children: [
            {
                path: "login",
                name: "login",
                component: () =>
                    import("@/views/authentication/Login.vue"),
                meta: {
                    pageTitle: "auth.Login",
                },
            },
            {
                path: "register",
                children: [
                    {
                        path: "",
                        name: "register",
                        component: () =>
                            import("@/views/authentication/register/Index.vue"),
                        meta: {
                            pageTitle: "auth.Create an account",
                        },
                    },
                    {
                        path: "verification",
                        name: "register-verification",
                        component: () =>
                            import("@/views/authentication/register/CodeConfirmation.vue"),
                        meta: {
                            pageTitle: "auth.Send code",
                        },
                    },
                    {
                        path: "data",
                        name: "register-data",
                        component: () =>
                            import("@/views/authentication/register/CompleteData.vue"),
                        meta: {
                            pageTitle: "auth.Complete the data",
                        },
                    },
                    {
                        path: "countries",
                        name: "register-countries",
                        component: () =>
                            import("@/views/authentication/register/ChooseCountries.vue"),
                        meta: {
                            pageTitle: "auth.Choose the countries you prefer",
                        },
                    },
                ]

            },

            {
                path: "password-reset",
                name: "password-reset",
                component: () =>
                    import("@/views/authentication/PasswordReset.vue"),
                meta: {
                    pageTitle: "auth.Password Recovery",
                },
            },
            {
                path: "code-confirmation",
                name: "code-confirmation",
                component: () =>
                    import("@/views/authentication/CodeConfirmation.vue"),
                meta: {
                    pageTitle: "auth.Send code",
                },
            },
            {
                path: "complete-data",
                name: "profile-complete-data",
                component: () =>
                    import("@/views/profile/CompleteData.vue"),
                meta: {
                    pageTitle: "navbar.profile",
                },
            },
            {
                path: "confirmation",
                name: "email-code-confirmation",
                component: () =>
                    import("@/views/profile/CodeConfirmation.vue"),
                meta: {
                    pageTitle: "navbar.profile",
                },
            },
            {
                path: "new-password",
                name: "new-password",
                component: () =>
                    import("@/views/authentication/NewPassword.vue"),
                meta: {
                    pageTitle: "auth.New password",
                },
            },
        ],
    },
];
