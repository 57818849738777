import {defineStore} from "pinia";
import {useAppsStore} from "@/stores/app";
import {useCookies} from "vue3-cookies";

const {cookies} = useCookies();

export const useUtilityStore = defineStore({
    id: "utility",
    state: () => ({
        searchData: [],
        filterData: null,
        privacyPolicy: null,
        terms: null,
        contactData: null,
    }),
    getters: {
        getSearchData: (state) => state.searchData,
        getFilterData: (state) => state.filterData,
        getPrivacyPolicy: (state) => state.privacyPolicy,
        getTerms: (state) => state.terms,
        getContactData: (state) => state.contactData,
    },
    actions: {
        async createStripeIntent(data) {
            return await this.$http
                .post(
                    `/web/utils/create-intent`,
                    data
                )
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    throw error;
                });
        },

        async search(data, shimmer = false) {

            const appStore = useAppsStore();
            if (shimmer) {
                appStore.addShimmerSection('search');
            }
            return await this.$http
                .post(
                    `/web/utils/search`,
                    data
                )
                .then((response) => {
                    this.searchData = response.data.data;
                    return true;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    if (shimmer) {
                        appStore.removeShimmerSection('search');
                    }
                });
        },

        async loadFilterData( shimmer = false) {

            const appStore = useAppsStore();
            if (shimmer) {
                appStore.addShimmerSection('packages');
            }
            return await this.$http
                .get(
                    `/web/utils/filter/data`
                )
                .then((response) => {
                    this.filterData = response.data.data;
                    return true;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    if (shimmer) {
                        appStore.removeShimmerSection('packages');
                    }
                });
        },

        async uploadFile(data) {
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .post(`/web/utils/files/upload`, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    appStore.loadingFinish();
                    return response.data.data;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                });
        },


        async loadPrivacyPolicy(shimmer = false) {
            const appStore = useAppsStore();
            if (shimmer) {
                appStore.addShimmerSection('privacyPolicy');
            } else {
                appStore.loadingStart();
            }
            return await this.$http
                .get(`/web/settings/privacy-policy`)
                .then((response) => {
                    this.privacyPolicy = response.data.data.privacy_policy;
                    return true;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    if (shimmer) {
                        appStore.removeShimmerSection('privacyPolicy');
                    } else {
                        appStore.loadingFinish();
                    }
                })
        },

        async loadTerms() {
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .get(`/web/settings/terms-conditions`)
                .then((response) => {
                    appStore.loadingFinish();
                    this.terms = response.data.data.terms_conditions;
                    return true;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                });
        },

        async loadContactData() {
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .get(`/web/settings/contact-data`)
                .then((response) => {
                    appStore.loadingFinish();
                    this.contactData = response.data.data;
                    return true;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                });
        },

        async contactUs(data) {
            return await this.$http
                .post(`/web/utils/contact-us`, data)
                .then((response) => {
                    this.$notificationMessage({
                        title: this.$t('success_messages.send_successfully'),
                        type: "success",
                    });
                    return true;
                })
                .catch((error) => {
                    throw error;
                });
        },
    },
});
