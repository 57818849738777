import Toast, { useToast } from "vue-toastification";
import ToastificationContent from "@/components/wameed/Toastification.vue";

const toastification = (app) => {
  const toast = useToast();
  const windowWidth = window.innerWidth;
  let position = "top-right";
  if (windowWidth < 960) {
    position = "top-center";
  }
  app.use(Toast, {
    hideProgressBar: true,
    closeOnClick: true,
    closeButton: false,
    icon: false,
    timeout: 3000,
    transition: "Vue-Toastification__fade",
  });
  return (app.config.globalProperties.$notificationMessage = (data) =>
    toast(
      {
        component: ToastificationContent,
        props: {
          title: data.title,
          type: data.type,
        },
      },
      {
        toastClassName: data.type + "-class",
        position: position,
      }
    ));
};
export default toastification;
