import authMiddleware from "../middleware/auth";

export default [
    {
        path: "",
        component: () => import("@/layouts/DefaultLayout.vue"),
        meta: {
            middleware: [authMiddleware],
        },
        children: [
            {
                path: "profile",
                name: "profile",
                component: () =>
                    import("@/views/profile/Index.vue"),
                redirect: {name: "profile-user-data"},
                children: [
                    {
                        path: "data",
                        children: [
                            {
                                path: "",
                                name: "profile-user-data",
                                component: () =>
                                    import("@/views/profile/UserData.vue"),
                                meta: {
                                    pageTitle: "navbar.profile",
                                },
                            },
                            {
                                path: "edit-password",
                                name: "profile-user-data-edit-password",
                                component: () =>
                                    import("@/views/profile/EditPassword.vue"),
                                meta: {
                                    pageTitle: "auth.Edit password",
                                },
                            },
                            {
                                path: "reset-password",
                                name: "profile-user-data-reset-password",
                                component: () =>
                                    import("@/views/profile/ResetPassword.vue"),
                                meta: {
                                    pageTitle: "common.Password Reset",
                                },
                            },
                        ]
                    },
                    {
                        path: "bookings",
                        children: [
                            {
                                path: "",
                                name: "profile-bookings",
                                component: () =>
                                    import("@/views/profile/bookings/Index.vue"),
                                meta: {
                                    pageTitle: "navbar.Bookings",
                                },
                            },

                        ]
                    },
                    {
                        path: "chats",
                        children: [
                            {
                                path: "",
                                name: "profile-chats",
                                component: () =>
                                    import("@/views/profile/chats/Index.vue"),
                                meta: {
                                    pageTitle: "navbar.Chats",
                                },
                            },
                        ]
                    },
                    {
                        path: "settings",
                        name: "profile-settings",
                        component: () =>
                            import("@/views/profile/Settings.vue"),
                        meta: {
                            pageTitle: "page.Site settings",
                        },
                    }
                ]
            },
        ],
    },
];
