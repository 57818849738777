const preventE = {
  mounted(el) {
    el.addEventListener('keydown', (event) => {
      if (event.key === 'e' || event.key === 'E') {
        event.preventDefault();
      }
      if (event.key === '-' && event.target.selectionStart !== 0) {
        event.preventDefault();
      }
    });


    // Handle paste event
    el.addEventListener('paste', (event) => {
      const pasteData = (event.clipboardData || window.clipboardData).getData('text');
      if (pasteData.includes('e') || pasteData.includes('E')) {
        event.preventDefault();
      }
      if (pasteData < 0) {
        event.preventDefault();
      }
    });

    // Handle input event
    el.addEventListener('input', (event) => {
      const value = event.target.value;
      if (value.includes('e') || value.includes('E') || value < 0) {
        event.target.value = value.replace(/e/gi, '');
      }
      if (value < 0) {
        event.target.value = value.replace(/-/g, '');
      }
    });
  },

};

export { preventE };