import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";
import { useAccountStore } from '@/stores/modules/account';

const { cookies } = useCookies();

export const useCartStore = defineStore({
    id: "cart",
    state: () => ({
        cart: null,
    }),
    getters: {
        getCartItems: (state) => state.cart,
    },
    actions: {
        async loadCart(data, shimmer = false) {
            const appStore = useAppsStore();
            if (shimmer) {
                appStore.addShimmerSection('cart');
            } else {
                appStore.loadingStart();
            }
            return await this.$http
                .get(
                    `/web/cart?buy_now=${data}`
                )
                .then((response) => {
                    this.cart = response.data.data;
                    return response.data.data;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    if (shimmer) {
                        appStore.removeShimmerSection('cart');
                    } else {
                        appStore.loadingFinish();
                    }
                })
        },
        async updateItemQty(data) {
            const accountStore = useAccountStore();
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .post(
                    `/web/cart/update/qty`,
                    data
                )
                .then(async (response) => {
                    appStore.loadingFinish();

                    this.$notificationMessage({
                        title: this.$t('success_messages.ticket_added_to_cart_successfully'),
                        type: "success",
                    });
                    this.cart = response.data.data;
                    await accountStore.loadAccountData();
                    return response.data.data;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                })
        },

        async addOrUpdateCart(data) {
            const accountStore = useAccountStore();
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .post(
                    `/web/cart/create`,
                    data
                )
                .then(async (response) => {
                    appStore.loadingFinish();

                    if (!data.buy_now) {
                        this.$notificationMessage({
                            title: this.$t('success_messages.ticket_added_to_cart_successfully'),
                            type: "success",
                        });
                    }
                    this.cart = response.data.data;
                    await accountStore.loadAccountData();
                    return response.data.data;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                })
        },

        async deleteFromCart(data) {
            const accountStore = useAccountStore();
            const appStore = useAppsStore();
            appStore.loadingStart();
            return await this.$http
                .post(
                    `/web/cart/delete`,
                    data
                )
                .then(async (response) => {
                    appStore.loadingFinish();

                    // this.$notificationMessage({
                    //     title: this.$t('success_messages.ticket_added_to_cart_successfully'),
                    //     type: "success",
                    //   });
                    this.cart = response.data.data;
                    await accountStore.loadAccountData();
                    return response.data.data;
                })
                .catch((error) => {
                    appStore.loadingFinish();
                    throw error;
                })
        },


    },
});
