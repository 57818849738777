import {defineStore} from "pinia";
import {useAppsStore} from "@/stores/app";
import {useCookies} from "vue3-cookies";

const {cookies} = useCookies();

export const useFaqsStore = defineStore({
    id: "faqs",
    state: () => ({
        faqs: [],
    }),
    getters: {
        getFaqs: (state) => state.faqs
    },
    actions: {
        async loadFaqs() {
            const appStore = useAppsStore();
            appStore.addShimmerSection('faqs');
            return await this.$http
                .get(
                    `/web/faqs`
                )
                .then((response) => {

                    this.faqs = response.data.data;
                    return true;
                })
                .catch((error) => {
                    throw error;
                }).finally(() => {
                    appStore.removeShimmerSection('faqs');
                });
        },
    },
});
