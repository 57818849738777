export const trackEvent = (eventName, eventData = {}) => {
    if (typeof fbq === "undefined") {
        console.warn("Meta Pixel is not initialized.");
        return;
    }
    fbq("track", eventName, eventData);
  
};

export const socialTrakingEvents = (name, eventData) => {
    let eventName =''
    if (name =='signup') {
        trackEvent( 'Signup', eventData.fb);
        trackSnapEvent("SIGN_UP", eventData.st);
        trackTtqEvent("Signup",eventData.ttq)
    }
    else if (name =='login') {
        trackEvent( 'Signin', eventData.fb);
        trackSnapEvent("LOGIN", eventData.st);
        trackTtqEvent("Signin",eventData.ttq)
    }
    else if(name == 'content_view'){
        trackEvent( 'ViewContent', eventData.fb);
        trackSnapEvent("VIEW_CONTENT", eventData.st);
        trackTtqEvent("ViewContent",eventData.ttq)

    }
    else if(name == 'add_to_cart'){
        trackEvent( 'AddToCart', eventData.fb);
        trackSnapEvent("ADD_CART", eventData.st);
        trackTtqEvent("AddToCart",eventData.ttq)

    }
    else if(name == 'purchase'){
        trackEvent( 'Purchase', eventData.fb);
        trackSnapEvent("PURCHASE", eventData.st);
        trackTtqEvent("CompletePayment",eventData.ttq)

    }else if(name == 'page_view'){
        trackEvent( 'PageView', eventData.fb);
        trackSnapEvent("PAGE_VIEW", eventData.st);
        trackTtqEvent("PageView",eventData.ttq)

    }
  
};

export const trackSnapEvent = (eventName, eventData = {}) => {
    if (typeof snaptr === "undefined") {
        console.warn("snaptr Pixel is not initialized.");
        return;
    }
    snaptr("track", eventName, eventData);
  
};

export const trackTtqEvent = (eventName, eventData = {}) => {
    if (typeof ttq === "undefined") {
        console.warn("ttq Pixel is not initialized.");
        return;
    }
    ttq.track(eventName, eventData);
  
}; 
export const initSnapEvent = (eventName, eventData = {}) => {
    if (typeof snaptr === "undefined") {
        console.warn("Meta Pixel is not initialized.");
        return;
    }
    snaptr('init',"e7e20c60-6189-4a2c-9fc2-aba85281d7cd", 
        {...eventData,
        'user_hashed_email':  hashEmail(eventData.user_email),
        'user_hashed_phone_number': hashEmail(eventData.user_email)
        });
  
};


