import { defineStore } from "pinia";
import { useAppsStore } from "@/stores/app";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();
import { getAuth, signInWithCustomToken } from "firebase/auth";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
  }),
  getters: {
  },
  actions: {
    async login(data) {
      return await this.$http.post(`user/auth/login`, data)
        .then((response) => {
          const date = new Date();
          const responseData = {
            ...response.data.data,
            exp_date: date.setSeconds(
              date.getSeconds() + response.data.data.token.expires_in,
            ),
          };


          this.$http.defaults.headers.common.Authorization = `Bearer ${response.data.data.token?.access_token}`;
          cookies.set("safarfy-user-login-data", JSON.stringify(responseData), response.data.data.token.remember_me == true ? '30d' : '1d');


          // return true;
          const auth = getAuth();
          signInWithCustomToken(auth, response.data.data.firebase_token).then(() => {
            return true;
          }).catch((error) => {
            console.log(error);
            return true;
          });
          return responseData
        })
        .catch((error) => {
          throw error;
        });
    },

    async firebaseLogin({ commit },) {
      await this._vm.$http
        .post(`firebase-login`)
        .then((response) => {
          if (response.status === 200) {
            const auth = getAuth();
            signInWithCustomToken(auth, response.data.data)
              .catch((error) => {
                console.log(error)
              });
          }
        })
        .catch((error) => {
          commit('failMessage', 'firebase_fail', { root: true });
          throw error;
        });
    },

    async forgetPassword(data) {
      return await this.$http.post(`user/auth/forget-password`, data)
        .then((response) => {
          return response.data.data;
        })
        .catch((error) => {
          throw error;
        });
    },

    async confirmToken(data) {
      return await this.$http.post(`user/auth/confirm-token`, data)
        .then((response) => {
          return response.data.data;
        })
        .catch((error) => {
          throw error;
        });
    },

    async resetPassword(data) {
      return await this.$http.post(`user/auth/reset-password`, data)
        .then((response) => {
          return response.data.data;
        })
        .catch((error) => {
          throw error;
        });
    },


    async register(data) {
      return await this.$http.post(`user/auth/register`, data)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          throw error;
        });
    },

    async registerByProvider(data) {
      const appStore = useAppsStore();
      appStore.loadingStart();
      return await this.$http.post(`user/auth/register/provider`, data)
        .then((response) => {
          appStore.loadingFinish();
          const date = new Date();
          const responseData = {
            ...response.data.data,
            exp_date: date.setSeconds(
              date.getSeconds() + response.data.data.token.expires_in,
            ),
          };

          this.$http.defaults.headers.common.Authorization = `Bearer ${response.data.data.token?.access_token}`;
          cookies.set("safarfy-user-login-data", JSON.stringify(responseData), response.data.data.token.remember_me == true ? '30d' : '1d');


          // return true;
          const auth = getAuth();
          signInWithCustomToken(auth, response.data.data.firebase_token).then(() => {
            return true;
          }).catch((error) => {
            console.log(error);
            return true;
          });
        })
        .catch((error) => {
          appStore.loadingFinish();
          throw error;
        });
    },


    async verifyAccount(data) {
      return await this.$http.post(`user/auth/verify-account`, data)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          throw error;
        });
    },

    async resentVerifyCode(data) {
      return await this.$http.post(`user/auth/resent-verify-code`, data)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          throw error;
        });
    },


    async completeData(data) {
      return await this.$http.post(`user/auth/complete-data`, data)
        .then((response) => {
          const date = new Date();
          const responseData = {
            ...response.data.data,
            exp_date: date.setSeconds(
              date.getSeconds() + response.data.data.token.expires_in,
            ),
          };

          this.$http.defaults.headers.common.Authorization = `Bearer ${response.data.data.token?.access_token}`;
          cookies.set("safarfy-user-login-data", JSON.stringify(responseData), response.data.data.token.remember_me == true ? '30d' : '1d');


          // return true;
          const auth = getAuth();
          signInWithCustomToken(auth, response.data.data.firebase_token).then(() => {
            return true;
          }).catch((error) => {
            console.log(error);
            return true;
          });
        })
        .catch((error) => {
          throw error;
        });
    },
    async completeDataAfterLogin(data) {
      return await this.$http.post(`user/auth/complete-data-2`, data)
        .then((response) => {


        })
        .catch((error) => {
          throw error;
        });
    },
  },
});
